<!-- 企业名片 -->
<template>
    <div style="text-align: left;">
        <el-card>
            <div class="pageCenter apply-container">
                <h3 class="text-center p-50">{{hasEdit==false?'添加':'编辑'}}员工名片</h3>
                <div>
                    <el-form :model="enterApplyForm" :rules="enterApplyFormRules" ref="enterApplyForm" label-width="120px">
                        <el-form-item label="用户">
                            <div v-if="selectObj">
                                <div class="df box">
                                    <img :src="$oucy.ossUrl + selectObj.ltUserDetailFull.detailAvatar" class="detailAvatar">
                                    <div class="text">
                                        <div class="f14">{{selectObj.ltUserDetailFull.detailNickname}}</div>
                                        <div class="f12">{{selectObj.userPhoneNumber}}</div>
                                    </div>
                                    <i class="el-icon-error delbox" @click="delSelctObj"></i>
                                </div>
                            </div>
                            <el-input v-else placeholder="搜索选择后自动录入信息" @focus="openDialogFormVisible"></el-input>
                        </el-form-item>
                        <el-form-item label="照片" prop="employeePic">
                            <upload-image @uploadImageBack="uploadImageBack">
                                <img v-if="enterApplyForm.employeePic" :src="$oucy.ossUrl+enterApplyForm.employeePic" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </upload-image>
                        </el-form-item>
                        <el-form-item label="姓名" prop="employeeName">
                            <el-input v-model="enterApplyForm.employeeName" autocomplete="off" placeholder="请输入名片所属员工姓名"></el-input>
                        </el-form-item>
                        <el-form-item label="职位" prop="employeeJob">
                            <el-input v-model="enterApplyForm.employeeJob" placeholder="请输入名片所属员工职位"></el-input>
                        </el-form-item>
                        <el-form-item label="电话" prop="employeePhone">
                            <el-input v-model="enterApplyForm.employeePhone" autocomplete="off" placeholder="请输入名片所属员工电话"></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱">
                            <el-input v-model="enterApplyForm.employeeEmail" autocomplete="off" placeholder="请输入名片所属员工常用邮箱"></el-input>
                        </el-form-item>
                        <el-form-item label="城市" v-if="isShowCityCode" prop="cityCode">
                            <el-cascader v-model="enterApplyForm.cityCode" style="width: 100%" :props="cityProps" placeholder="请输入名片所属员工城市"></el-cascader>
                        </el-form-item>
                        <el-form-item label="详细地址" prop="employeeAdd">
                            <el-input v-model="enterApplyForm.employeeAdd" type="text" :rows="2" autocomplete="off" placeholder="请填写详细地址" @focus="enterApplyForm.employeeLatitude?'':onAddrSelect(addrPoint.JYDZ)"/>
                            <el-button @click="onAddrSelect(addrPoint.JYDZ)" icon="el-icon-location" type="text">选择</el-button>
                        </el-form-item>
                        <el-form-item label="个人简介" prop="employeeSignature">
                            <div style=""> 
                                <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 10}" placeholder="请输入名片员工个人简介" maxlength="140" show-word-limit v-model="enterApplyForm.employeeSignature">
                                </el-input>
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="keywordFiltr('submit')" type="primary" v-if="hasEdit">保存名片</el-button>
                            <el-button @click="keywordFiltr('submit')" type="primary" v-else>新增名片</el-button>
                            <el-button @click="$oucy.back()">取 消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>

        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle" />
        <selectUserDialog ref="selectUserDialog" @callBack="callBack" />
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import addr from "@/components/Addr";
import uploadImage from "@/components/uploadImage";
import { localSet, localGet, localDel } from "@/store/store"
import { ltIdentity, cardCategory, cardEmployee } from "@/service"
export default {
    name: "EnterpriseApply1111",
    components: {
        addr,
        uploadImage
    },
    data() {
        return {
            identitys: [],
            sites: [],
            enterpriseEnterStatus: null,
            enterApplyForm: {
                "employeePic": null,
                "employeeName": "",
                "employeeJob": "",
                "employeePhone": "",
                "employeeEmail": "",
                "employeeSignature": "",
                cityCode: null,
                "employeeAdd": "",
                "employeeLatitude": null,
                "employeeLontitude": null,
                employeeUser:null
            },
            enterApplyFormRules: {
                cardCategory: [
                    { required: true, message: '请选择名片类型', trigger: 'blur' },
                ],
                employeePic: [
                    { required: true, message: '请上传头像', trigger: 'blur' },
                ],
                employeeName: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                employeeJob: [
                    { required: true, message: '请输入职位', trigger: 'blur' },
                ],
                employeePhone: [
                    { required: true, message: '请输入电话', trigger: 'blur' },
                ],
                employeeAdd: [
                    { required: true, message: '请选择详细地址', trigger: 'blur' },
                ],
                cityCode: [
                    { required: true, message: '请选择城市', trigger: 'blur' },
                ],
                employeeSignature: [
                    { required: true, message: '请输入个人简介', trigger: 'blur' },
                ],
            },

            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    oucy.findChina(level === 0 ? null : node.value).then(res => {
                        resolve(Array.from(res).map(item => ({
                            value: item.chinaId,
                            label: item.chinaName,
                            leaf: !item.hasChildren
                        })));
                    });
                }
            },
            addrSelectVisible: false,
            addrSelectPoint: null,
            addrPoint: {
                JYDZ: 1,
                GCDZ: 2,
                ZTDZ: 3
            },
            enterprise: null,
            enterpriseId: null,
            ltIdentityList: [],
            isShowCityCode: false,
            cardEmployeeDetail: {},
            hasEdit: null,
            selectObj: null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.enterpriseId = enterprise.id
        }
        this.id = this.$route.query.id
        if (this.id) {
            this.hasEdit = true
            // 查企业名片详情
            this.getEmployeeDetail()
        } else {
            this.isShowCityCode = true
            this.hasEdit = false
        }
    },
    methods: {
        keywordFiltr(fn){
            this.$oucy.keywordFiltr(this.enterApplyForm).then(res=>{
               this[fn]() 
            },err=>{})
        },
        // 查企业名片详情
        getEmployeeDetail() {
            cardEmployee.getEmployeeDetail({ id: this.id, type: false }).then(res => {
                // 有名片数据
                if (res) {
                    this.enterApplyForm = Object.assign(this.enterApplyForm,res)
                    // this.enterApplyForm.cityCode = res.ltChinaOfEmployeeCardRegion.idPath
                    this.enterApplyForm.cityCode = res.chinaFull_path.idPath
                    // 有绑定员工的 回填
                    if(res.ltUserAuthOfEmployeeUser){
                        this.selectObj=res.ltUserAuthOfEmployeeUser
                    }
                } else {

                }
                this.isShowCityCode = true
            })
        },
        // 打开选择用户
        openDialogFormVisible() {
            this.$refs.selectUserDialog.openDialogFormVisible()
        },
        // 发布企业员工名片
        createCardEmployee() {
            cardEmployee.createCardEmployee(this.enterApplyForm).then(res => {
                this.$alert('发布成功，请等待审核通过')
                this.$oucy.back()
            })
        },
        // 修改企业员工名片
        updateCardEmployee() {
                        console.log(7)
            cardEmployee.updateCardEmployee(this.enterApplyForm).then(res => {
                // 有名片数据
                        console.log(8)
                this.$alert('修改成功，请等待审核通过')
                this.$oucy.back()

            })
        },
        // 选择图片
        uploadImageBack(v) {
            this.enterApplyForm.employeePic = v
            console.log(v)
        },

        // 提交
        submit: function() {
            const me = this;
                        console.log(5)
            this.enterApplyForm.employeeEnterprise = this.enterpriseId
            console.log(me.enterApplyForm);
            me.$refs["enterApplyForm"].validate((valid) => {
                if (valid) {
                    this.enterApplyForm.cardRegion = this.enterApplyForm.cityCode[this.enterApplyForm.cityCode.length - 1]
                    this.enterApplyForm.employeeUser = this.selectObj?this.selectObj.id:null
                    if (me.hasEdit) {
                        console.log(6)
                        this.updateCardEmployee()
                    } else {
                        this.createCardEmployee()
                    }

                }
            });
        },
        // 选择用户回调
        callBack(v) {
            if (v.ltUserDetailFull && v.ltUserDetailFull.detailAvatar) {
                this.enterApplyForm.employeePic = v.ltUserDetailFull.detailAvatar
            }
            if (v.ltUserDetailFull && v.ltUserDetailFull.detailNickname) {
                this.enterApplyForm.employeeName = v.ltUserDetailFull.detailNickname
            }
            if (v.userPhoneNumber && v.userPhoneNumber) {
                this.enterApplyForm.employeePhone = v.userPhoneNumber
            }
            this.selectObj = v
        },
        // 删除选择用户
        delSelctObj() {
            this.selectObj = null
        },
        // 打开地址
        onAddrSelect: function(point) {
            this.addrSelectVisible = true;
            this.addrSelectPoint = point;
        },
        addrSelectHandle: function(addr) {
            console.log('addr::', addr)
            const me = this;
            this.addrSelectVisible = false;
            let thisAddr = addr.address + '(' + addr.name + ')';
            if (!!me.addrSelectPoint) {
                if (me.addrSelectPoint === me.addrPoint.JYDZ) {
                    me.enterApplyForm.employeeLatitude = addr.location.lat
                    me.enterApplyForm.employeeLontitude = addr.location.lng
                    me.enterApplyForm.employeeAdd = thisAddr;
                } else if (me.addrSelectPoint === me.addrPoint.GCDZ) {
                    me.enterApplyForm.addrGC = thisAddr;
                    me.enterpriseAddrGcLatitude = addr.location.lat
                    me.enterpriseAddrGcLongtitude = addr.location.lng
                } else if (me.addrSelectPoint === me.addrPoint.ZTDZ) {
                    me.enterApplyForm.addrZT = thisAddr;
                    me.enterpriseAddrZtLatitude = addr.location.lat
                    me.enterpriseAddrZtLongtitude = addr.location.lng
                }
            }
        },

    }
}
</script>
<style scoped>
.apply-container {
    width: 500px;
    min-height: 600px;
    padding: 0 0 50px 0;
}

.editor {
    line-height: normal !important;
    height: 200px;
}

.editor>>>.ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0;
}

.editor>>>.ql-container.ql-snow {
    border-radius: 0 0 4px 4px;
}

.search-addr-name {
    color: #000000;
}

.search-addr-address {
    color: #5c5b75;
    font-size: 12px;
    padding: 0 10px;
}

.search-addr-tel {
    font-style: italic;
}

.avatar-uploader>>>.el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}

.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}

.cropper-content .cropper {
    width: auto;
    height: 300px;
}

.detailAvatar {
    width: 42px;
    height: 42px;
}

.box {
    border: 1px #f1f1f1 solid;
    padding: 10px;
    position: relative;
}

.text {
    line-height: 20px;
    color: #333;
    padding-left: 10px;
}

.delbox {
    font-size: 18px;
    color: rgba(0, 0, 0, .5);
    padding: 4px;
    position: absolute;
    right: -10px;
    top: -10px;
}
</style>